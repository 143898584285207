(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/client/create-jupiter-instance.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/client/create-jupiter-instance.js');
'use strict';

const {
  jupiterFetch
} = svs.core;
const createJupiterInstance = () => async function () {
  const response = await jupiterFetch.fetch(...arguments);
  const data = await response.json();
  if (!response.ok) {
    var _data$error;
    throw new Error((_data$error = data.error) !== null && _data$error !== void 0 ? _data$error : 'Error occurred while fetching');
  }
  return data;
};
setGlobal('svs.components.marketplaceCompetition.competitionHandler.client.createJupiterInstance', createJupiterInstance);

 })(window);