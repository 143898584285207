(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/utils.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/utils.js');
'use strict';

const filterUniqueProducts = competitions => {
  const productTable = {};
  for (const competition of competitions) {
    if (productTable[competition.getProductId()]) {
      continue;
    }
    productTable[competition.getProductId()] = competition.getCompetitionId();
  }
  return Object.values(productTable);
};
const toExport = {
  filterUniqueProducts
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.utils', toExport);
}

 })(window);