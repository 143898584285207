(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/common/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/common/constants.js');
'use strict';

const playerCompetitionDetailStatus = {
  FETCHED: 'playerCompetitionDetailStatus.fetched',
  NOT_FETCHED: 'playerCompetitionDetailStatus.not_fetched'
};
const competitionStates = {
  NO_COMPETITION: 'NO_COMPETITION',
  UPCOMING: 'UPCOMING',
  FINISHED: 'FINISHED',
  REG_OPEN_DRAW_DEFINED: 'REG_OPEN_DRAW_DEFINED',
  REG_CLOSED_DRAW_DEFINED: 'REG_CLOSED_DRAW_DEFINED',
  REG_OPEN_DRAW_OPEN: 'REG_OPEN_DRAW_OPEN',
  REG_CLOSED_DRAW_OPEN: 'REG_CLOSED_DRAW_OPEN',
  REG_OPEN_DRAW_LIVE: 'REG_OPEN_DRAW_LIVE',
  REG_CLOSED_DRAW_LIVE: 'REG_CLOSED_DRAW_LIVE',
  REG_OPEN_DRAW_FINALIZED: 'REG_OPEN_DRAW_FINALIZED',
  REG_CLOSED_DRAW_FINALIZED: 'REG_CLOSED_DRAW_FINALIZED'
};
const competitionBackendState = {
  COMPETITION_COMPLETE: 'CompetitionComplete',
  DEFINED: 'Defined',
  OPEN: 'Open'
};
const config = {
  NUMBER_WEEKS_REGISTER: 1
}; 

const competitionCacheTime = 10 * 60 * 1000;
const toExport = {
  LOGGER_NAME: 'marketplace-competition:competition-handler',
  competitionStates,
  competitionBackendState,
  config,
  competitionCacheTime,
  playerCompetitionDetailStatus
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.common.constants', toExport);
}

 })(window);