(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/common/create-competition-handler-base.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/common/create-competition-handler-base.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let createCompetitionModel;
let isCompetitionModel;
let logger;
let drawHandler;
let DrawState;
let constants;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  const _createCompetitionModel = require('./create-competition-model.es6');
  createCompetitionModel = _createCompetitionModel.createCompetitionModel;
  isCompetitionModel = _createCompetitionModel.isCompetitionModel;
  logger = require('../server/logger');
  drawHandler = trinidad.components.require('sport', 'draw-handler').api;
  DrawState = require('../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6').DrawState;
  constants = require('./constants.es6');
} else {
  const _createCompetitionModel = svs.components.marketplaceCompetition.competitionHandler.common;
  createCompetitionModel = _createCompetitionModel.createCompetitionModel;
  isCompetitionModel = _createCompetitionModel.isCompetitionModel;
  logger = svs.components.marketplaceCompetition.competitionHandler.client.logger;
  drawHandler = svs.components.sport.drawHandler.drawHandler;
  DrawState = svs.components.sport.tipsenShared.DrawState;
  constants = svs.components.marketplaceCompetition.competitionHandler.common.constants;
}
const {
  competitionBackendState
} = constants;
const constructCompetitionMultifetch = competitionIds => {
  const competitionUrl = competitionIds.map(competitionId => "/competition/2/competitions/".concat(competitionId));
  return "/multifetch?serial=true&urls=".concat(competitionUrl.join('|'));
};
const createModelCollection = () => {
  const competitionTable = new Map();
  const models = new Map();
  const addModel = competition => {
    const competitionModel = createCompetitionModel();
    competitionModel.setData(competition);
    if (!isCompetitionModel(competitionModel)) {
      throw new Error('Competition model must be provided');
    }
    const productId = competitionModel.getProductId();
    const competitionId = competitionModel.getCompetitionId();
    if (models.get(competitionId)) {
      throw new Error("Competition already exists: ".concat(competitionId));
    }
    models.set(competitionId, competitionModel);
    const productCompetitions = competitionTable.get(productId) || [];
    competitionTable.set(productId, productCompetitions.concat(competitionId));
    return competitionModel;
  };
  const setModel = competitionModel => {
    if (!isCompetitionModel(competitionModel)) {
      throw new Error('Competition model must be provided');
    }
    models.set(competitionModel.getCompetitionId(), competitionModel);
    logger.debug("Set competitionModel: ".concat(competitionModel));
  };
  const getCollection = () => models;
  const getModel = competitionId => models.get(competitionId);
  const getSerializeModel = () => ({
    competitions: Object.fromEntries(models.entries()),
    productRelationsTable: Object.fromEntries(competitionTable)
  });
  const hasProduct = productId => competitionTable.has(productId);
  const hasCompetition = competitionId => models.has(competitionId);
  const clear = () => {
    competitionTable.clear();
    models.clear();
  };
  return {
    addModel,
    setModel,
    getCollection,
    getSerializeModel,
    getModel,
    hasProduct,
    hasCompetition,
    clear
  };
};
const createSubscriptionModel = (socket, cache) => competitionId => {
  const competition = cache.getModel(competitionId);
  let _currentSubscribedDraw = null;
  let _isSubscribed = false;
  let _unsubscribeFn;
  let _subscriptions = [];
  const handleSocketUpdate = draw => {
    logger.debug("Socket update on competition: ".concat(competitionId));
    competition.updateCompetitionStatus(draw);
    if (draw.getDrawState() === DrawState.Finalized) {
      logger.info("Competition draw finished, unsubscribe");
      unsubscribeToSocketForActiveCompetitionDraw();
    }
    if (!competition.isFinished() && draw.getDrawState() === DrawState.Finalized) {
      logger.info("Subscribe to next draw in competition");
      competition.updateCurrentDrawNumber();
      subscribeToSocketForActiveCompetitionDraw();
    }
    for (const subscription of _subscriptions) {
      subscription(competition);
    }
  };
  const subscribeToSocketForActiveCompetitionDraw = () => {
    if (_isSubscribed) {
      return;
    }
    const productId = competition.getProductId();
    const drawNumber = competition.getCurrentDrawNumber();
    _currentSubscribedDraw = drawNumber;
    _unsubscribeFn = socket.subscribe(productId, drawNumber, handleSocketUpdate);
    _isSubscribed = true;
  };
  const unsubscribeToSocketForActiveCompetitionDraw = () => {
    _isSubscribed = false;
    _currentSubscribedDraw = null;
    _unsubscribeFn();
  };
  const add = callback => {
    if (!_isSubscribed) {
      subscribeToSocketForActiveCompetitionDraw();
    }
    _subscriptions.push(callback);
  };
  const remove = callback => {
    _subscriptions = _subscriptions.filter(_callback => _callback !== callback);
    if (!_subscriptions.length) {
      unsubscribeToSocketForActiveCompetitionDraw();
    }
  };
  const unsubscribe = () => {
    _subscriptions.length = 0;
    unsubscribeToSocketForActiveCompetitionDraw();
  };
  return {
    add,
    remove,
    unsubscribe
  };
};
const createSubscriptionHandler = (socket, cache) => {
  const subscriptions = new Map();
  const get = competitionId => {
    let subObj = subscriptions.get(competitionId);
    if (!subObj) {
      subObj = createSubscriptionModel(socket, cache)(competitionId);
      subscriptions.set(competitionId, subObj);
    }
    return subObj;
  };
  const destroy = () => {
    subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    subscriptions.clear();
  };
  return {
    get,
    destroy
  };
};
const createCompetitionHandlerBase = function () {
  let privateInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  privateInterface.competitionCache = createModelCollection();

  let _cmsCompetitionIds = [];
  const subscriptions = createSubscriptionHandler(drawHandler(), privateInterface.competitionCache);
  privateInterface.getSubscriptions = () => subscriptions;
  const getAndSetCachedCompetitionModel = (competitionData, persist) => {
    let competitionModel;
    if (persist) {
      competitionModel = privateInterface.competitionCache.getModel(competitionData.id);
      if (!competitionModel) {
        competitionModel = privateInterface.competitionCache.addModel(competitionData);
      } else {
        competitionModel.setData(competitionData);
      }
    } else {
      competitionModel = createCompetitionModel();
      competitionModel.setData(competitionData);
    }
    return competitionModel;
  };
  privateInterface.setCmsCompetitionIds = cmsCompetitionIds => {
    _cmsCompetitionIds = cmsCompetitionIds;
    logger.debug("Set cmsCompetitionIds: ".concat(cmsCompetitionIds));
  };
  privateInterface.getCmsCompetitionIds = () => _cmsCompetitionIds;
  privateInterface.fetchCompetitions = async function (competitionIds) {
    let {
      persist = true
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    logger.info("fetch competitions for ids: ".concat(competitionIds, ", should persist: ").concat(persist));
    const path = constructCompetitionMultifetch(competitionIds);
    const competitionsResponse = await privateInterface.jupiter(path, {
      method: 'GET'
    });
    const models = [];
    for (const [index, competitionId] of Object.entries(competitionIds)) {
      const competitionResponse = competitionsResponse.responses[index];
      if (competitionResponse.error) {
        var _competitionResponse$, _competitionResponse$2;
        logger.warn("Failed to fetch competition: ".concat(competitionId));
        models.push(new Error("Unable to fetch competition: ".concat(competitionId, ". Error: ").concat((_competitionResponse$ = (_competitionResponse$2 = competitionResponse.error) === null || _competitionResponse$2 === void 0 ? void 0 : _competitionResponse$2.message) !== null && _competitionResponse$ !== void 0 ? _competitionResponse$ : '')));
        _cmsCompetitionIds = privateInterface.getCmsCompetitionIds().filter(id => id !== competitionId);
        continue;
      }
      const competitionData = competitionResponse.competition;
      const competitionModel = getAndSetCachedCompetitionModel(competitionData, persist);
      let draw;
      if (competitionModel.getBackendState() !== competitionBackendState.DEFINED && competitionModel.getBackendState() !== competitionBackendState.COMPETITION_COMPLETE) {
        try {
          draw = await drawHandler().fetch(competitionModel.getProductId(), competitionModel.getCurrentDrawNumber(), false, true);
        } catch (_unused) {
          logger.info("Failed fetching draw for competition: ".concat(competitionId));
        }
      }
      competitionModel.updateCompetitionStatus(draw);
      models.push(competitionModel);
    }
    return models;
  };
  return function () {
    let publicInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    publicInterface.$getModel = () => {};
    publicInterface.getModel = () => _objectSpread(_objectSpread({}, privateInterface.competitionCache.getSerializeModel()), {}, {
      activeCompetitionIds: privateInterface.getCmsCompetitionIds()
    });
    publicInterface.$revalidateCompetitions = () => {};
    publicInterface.subscribe = async (competitionId, callback) => {
      await publicInterface.getCompetition(competitionId);
      subscriptions.get(competitionId).add(callback);
      const unsubscribe = () => {
        subscriptions.get(competitionId).remove(callback);
      };
      return unsubscribe;
    };
    publicInterface.getCompetition = async competitionId => {
      const competitionIds = [].concat(competitionId);
      const competitions = [];
      const competitionsToFetch = [];
      const missingCompetitionRetPositions = [];
      competitionIds.forEach((compId, position) => {
        const comp = privateInterface.competitionCache.getModel(compId);
        if (!comp) {
          missingCompetitionRetPositions.push(position);
          competitionsToFetch.push(compId);
        }
        competitions.push(comp);
      });
      if (competitionsToFetch.length) {
        const fetchedCompetitions = await privateInterface.fetchCompetitions(competitionsToFetch);
        fetchedCompetitions.forEach((comp, fetchedPosition) => {
          const insertPosition = missingCompetitionRetPositions[fetchedPosition];
          competitions[insertPosition] = comp;
        });
      }
      if (competitions.length === 1) {
        const [competition] = competitions;
        if (competition instanceof Error) {
          throw competition;
        }
        return competition;
      }
      return competitions;
    };
    publicInterface.getActiveCompetition = productId => {
      const competitionIds = privateInterface.getCmsCompetitionIds();
      const comps = competitionIds.map(compId => privateInterface.competitionCache.getModel(compId));
      return comps.find(comp => (comp === null || comp === void 0 ? void 0 : comp.getProductId()) === productId);
    };
    publicInterface.getActiveCompetitions = () => {
      const competitionIds = privateInterface.getCmsCompetitionIds();
      return competitionIds.map(compId => privateInterface.competitionCache.getModel(compId));
    };
    publicInterface.getCompetitionParticipants = async competitionId => {
      if (!competitionId) {
        return;
      }
      const comp = await privateInterface.jupiter("/competition/1/competitions/".concat(competitionId, "/participants"), {
        method: 'GET'
      });
      return comp;
    };
    publicInterface.destroy = () => {
      subscriptions.destroy();
      privateInterface.competitionCache.clear();
    };
    return publicInterface;
  };
};
if (svs.isServer) {
  module.exports = createCompetitionHandlerBase;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.common.createCompetitionHandlerBase', createCompetitionHandlerBase);
}

 })(window);