(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/common/create-player-competition-handler-base.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/common/create-player-competition-handler-base.js');
'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let logger;
let normalizePlayerCompetitions;
let normalizeTeams;
let playerCompetitionDetailStatus;
if (svs.isServer) {
  logger = require('../server/logger');
  normalizePlayerCompetitions = require('../../competition-helper/assets/javascripts/normalize-player-competitions.es6').normalizePlayerCompetitions;
  playerCompetitionDetailStatus = require('./constants.es6').playerCompetitionDetailStatus;
} else {
  logger = svs.components.marketplaceCompetition.competitionHandler.client.logger;
  normalizePlayerCompetitions = svs.components.marketplaceCompetition.competitionHelper.normalizePlayerCompetition.normalizePlayerCompetitions;
  normalizeTeams = svs.components.marketplaceCompetition.competitionHelper.normalizeTeams;
  playerCompetitionDetailStatus = svs.components.marketplaceCompetition.competitionHandler.common.constants.playerCompetitionDetailStatus;
}
const getPath = (competitionId, teamIds, shouldFetchIndividualResults) => {
  const resultsPaths = [];
  if (shouldFetchIndividualResults) {
    resultsPaths.push("/player/2/competitions/".concat(competitionId, "/result"));
  }
  if (teamIds) {
    teamIds.forEach(teamId => {
      resultsPaths.push("/player/2/competitions/".concat(competitionId, "/team/result?teamId=").concat(teamId));
    });
  }
  return resultsPaths.length === 1 ? resultsPaths.pop() : "/multifetch?serial=true&urls=".concat(resultsPaths.map(path => encodeURIComponent(path)).join('|'));
};
const createPlayerCompetitionHandlerBase = function () {
  let privateInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let _playerCompetitionDetails = {};

  privateInterface.setPlayerCompetitionDetails = playerCompetitionDetails => {
    _playerCompetitionDetails = playerCompetitionDetails;
  };

  privateInterface.setPlayerCompetition = _ref => {
    let {
      competitionId
    } = _ref;
    _playerCompetitionDetails.playerCompetitions[competitionId] = {
      competitionId,
      isDirty: true
    };
  };

  privateInterface.setPlayerCompetitionDraw = _ref2 => {
    var _playerCompetitionDet;
    let {
      drawNumber,
      competitionId
    } = _ref2;
    if (!((_playerCompetitionDet = _playerCompetitionDetails) !== null && _playerCompetitionDet !== void 0 && _playerCompetitionDet.playerCompetitionDraws)) {
      _playerCompetitionDetails.playerCompetitionDraws = {};
    }
    if (_playerCompetitionDetails.playerCompetitionDraws[competitionId]) {
      _playerCompetitionDetails.playerCompetitionDraws[competitionId] = [..._playerCompetitionDetails.playerCompetitionDraws[competitionId], drawNumber];
    } else {
      _playerCompetitionDetails.playerCompetitionDraws[competitionId] = [drawNumber];
    }
    _playerCompetitionDetails.playerCompetitionDraws = _objectSpread(_objectSpread({}, _playerCompetitionDetails.playerCompetitionDraws), {}, {
      isDirty: true
    });
  };
  privateInterface.getPlayerCompetitionDetails = () => _playerCompetitionDetails;
  return function () {
    let publicInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    publicInterface.fetchPlayerCompetitions = async function () {
      let activeOnly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let req = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      const response = await privateInterface.jupiter("/player/1/competitions?activeOnly=".concat(activeOnly), {
        method: 'GET'
      }, req);
      logger.debug('Fetch playerCompetitions');
      const comps = normalizePlayerCompetitions(response.competitions);
      const status = !response.error ? playerCompetitionDetailStatus.FETCHED : playerCompetitionDetailStatus.NOT_FETCHED;
      return [comps, status];
    };
    publicInterface.fetchPlayerCompetitionDraws = async (playerCompetitionIds, req) => {
      try {
        if (!(playerCompetitionIds instanceof Array) || !playerCompetitionIds.length) {
          logger.info("No ids provided for player competition draws status, ids: ".concat(playerCompetitionIds));
          return;
        }
        const urls = playerCompetitionIds.map(compId => encodeURIComponent("/player/1/competitions/".concat(compId, "/drawstatus")));
        const response = await privateInterface.jupiter("/multifetch?serial=true&urls=".concat(urls.join('|')), {
          method: 'GET'
        }, req);
        const structurePlayerCompetitionsDraws = {};
        const responses = response.responses;
        responses.forEach(value => {
          if (value.hasOwnProperty('error')) {
            throw value.error;
          }
          structurePlayerCompetitionsDraws[playerCompetitionIds[responses.indexOf(value)]] = value.participatedDraws;
        });
        return structurePlayerCompetitionsDraws;
      } catch (err) {
        logger.warn("Error fetching player competition draws: ".concat(err === null || err === void 0 ? void 0 : err.message, " ").concat(JSON.stringify(err)));
      }
    };
    publicInterface.fetchPlayerCompetitionDetails = async function () {
      let competitionIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let activeOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let shouldFetchAllDraws = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let req = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
      let playerCompetitionDraws;
      const [playerCompetitions, status] = await publicInterface.fetchPlayerCompetitions(activeOnly, req);
      const playerCompetitionIds = [];
      if ((competitionIds.length || shouldFetchAllDraws) && Object.values(playerCompetitions || {}).length) {
        Object.values(playerCompetitions).forEach(playerComp => {
          if (competitionIds.includes(playerComp.competitionId) || shouldFetchAllDraws) {
            playerCompetitionIds.push(playerComp.competitionId);
          }
        });
      }
      if (playerCompetitionIds.length) {
        logger.info("Fetching player competition draws for ids: ".concat(playerCompetitionIds));
        playerCompetitionDraws = await publicInterface.fetchPlayerCompetitionDraws(playerCompetitionIds, req);
      }
      return [playerCompetitions, playerCompetitionDraws, status];
    };
    publicInterface.fetchPlayerCompetitionResults = async function (competitionId, teamIds, shouldFetchIndividualResults) {
      let req = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
      if (!competitionId) {
        return {
          responses: [undefined, undefined]
        };
      }
      const paths = getPath(competitionId, teamIds, shouldFetchIndividualResults);
      const result = await privateInterface.jupiter(paths, {
        method: 'GET'
      }, req);
      return result;
    };
    publicInterface.fetchPlayerCompetitionTeams = async competitionId => {
      const response = await privateInterface.jupiter("/player/2/competitions/".concat(competitionId, "/teams"), {
        method: 'GET'
      });
      return {
        teams: normalizeTeams(response.teams)
      };
    };
    publicInterface.getPlayerCompetitions = () => _playerCompetitionDetails;
    publicInterface.getPlayerCompetitionDetails = () => logger.warn('getPlayerCompetitionDetails should be implemented by server / client');
    return publicInterface;
  };
};
if (svs.isServer) {
  module.exports = createPlayerCompetitionHandlerBase;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.common.createPlayerCompetitionHandlerBase', createPlayerCompetitionHandlerBase);
}

 })(window);