(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/competition-handler/competition-handler.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/competition-handler/competition-handler.js');
'use strict';

let createCompetitionHandler;
if (svs.isServer) {
  createCompetitionHandler = require('./server/create-competition-handler');
} else {
  createCompetitionHandler = svs.components.marketplaceCompetition.competitionHandler.client.createCompetitionHandler;
}
let instance;
const competitionHandler = () => {
  if (!instance) {
    instance = createCompetitionHandler();
  }
  return instance;
};
if (svs.isServer) {
  module.exports = competitionHandler;
} else {
  setGlobal('svs.components.marketplaceCompetition.competitionHandler.competitionHandler', competitionHandler);
}

 })(window);